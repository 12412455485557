<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 language-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("global.currency.currency") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper language-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global.search.search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <section class="languages" v-if="currencies && currencies.data">
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          v-for="currency in listOfCurrencies"
          :key="currency.id"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="selectCurrency(currency)"
          >
            <span class="label">{{ displayCurrency(currency.id) }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  :icon="[
                    selectedCurrency && currency.id == selectedCurrency.id
                      ? '#cx-app1-checkmark'
                      : '#cx-app1-arrow-right-12x12'
                  ]"
                  :width="[
                    selectedCurrency && currency.id == selectedCurrency.id
                      ? '20'
                      : '12'
                  ]"
                  :height="[
                    selectedCurrency && currency.id == selectedCurrency.id
                      ? '20'
                      : '12'
                  ]"
                ></icon>
              </span>
            </span>
          </button>
        </li>
      </ul>
    </section>
  </mobile-screen>
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState } from "vuex";
import currenciesMixin from "@/services/mixins/currencies/currencies-mixin";

export default {
  name: "CurrencySelection",
  data() {
    return {
      alphabet: [...Array(26)]
        .map((_, y) => String.fromCharCode(y + 65))
        .join(""),
      searchQuery: ""
    };
  },
  mixins: [currenciesMixin],
  computed: {
    ...mapState("currency", ["currencies", "selectedCurrency"]),
    listOfCurrencies() {
      if (
        this.currencies &&
        this.currencies.data &&
        this.currencies.data.length
      ) {
        let currencies = this.currencies.data.slice(0);

        const query = this.searchQuery;

        if (query && query.length > 1) {
          currencies = currencies.filter(
            item =>
              item.name.toLowerCase().includes(query.toLowerCase()) ||
              item.code.toLowerCase().includes(query.toLowerCase()) ||
              item.symbol.toLowerCase().includes(query.toLowerCase())
          );
        }

        return currencies.sort((a, b) => {
          if (this.selectedCurrency && b.id == this.selectedCurrency.id) {
            return 1;
          }
          if (this.selectedCurrency && a.id == this.selectedCurrency.id) {
            return -1;
          }
          if (a.name < b.name) {
            return -1;
          }
          if (b.name < a.name) {
            return 1;
          }
          return 0;
        });
      }
      return null;
    }
  },
  methods: {
    ...mapActions("currency", ["setSelectedCurrency"]),
    selectCurrency(currency) {
      this.setSelectedCurrency(currency);
      this.$router.push({ name: this.backLinkName });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
